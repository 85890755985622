import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from "styled-components"
import Sidebar from './sidebar';

const duration = 300;

const BehindBackground = styled.div`
  position: fixed;
  top:0;left:0;
  width:100vw;
  height:100vh;
  z-index: 99998;
  background: rgba(0,0,0,.5);
  background:rgba(9, 30, 66, 0.54);
  &.background-transition-enter {
    opacity: 0;
    visibility: hidden;
  }
  &.background-transition-enter-active {
    transition: all ${duration}ms;
    opacity: 1;
    visibility: visible;
  }
  &.background-transition-exit {
    opacity: 1;
    visibility: visible;
  }
  &.background-transition-exit-active {
    transition: all ${duration}ms;
    opacity: 0;
    visibility: hidden;
  }
`

type Props = {
  isShowing: boolean,
  hide(): void,
  title: string
  widthPercentage?: number
  closeOnDocumentClick?: boolean
  children: React.ReactNode
  className?:string
  position?:"right" | "left" 
  darkMode?: boolean
  darkModeBgColor?:string
  disabledAnimation?: boolean

}

const DefaultProps: Props = {
  isShowing: false,
  hide: ()=> {},
  title: '',
  widthPercentage: 30,
  closeOnDocumentClick: false,
  children: <></>,
  position: "right",
  darkMode: false,
  darkModeBgColor: "#2a3c4e"

}

const SlidingSidebar: React.FC<Props> = (props) => {

  //@ts-ignore
  const ModalWithTransitionStyles = styled(Sidebar).withConfig({componentId: `use-sidebar-wrapper${props.darkMode ? "-dark" :""}`})`
  &.modal-transition-enter {
    transform: ${props => props.position === "right" ? "translateX(100%)" : "translateX(-100%)"};
  }
  &.modal-transition-enter-active {
    transition: transform ${duration}ms;
    transform: translateX(0);
  }
  &.modal-transition-exit {
    transform: translateX(0);
  }
  &.modal-transition-exit-active {
    transition: transform ${duration}ms;
    transform: ${props => props.position === "right" ? "translateX(100%)" : "translateX(-100%)"};
  }
`;


    return(
      <>
        <CSSTransition
            in={props.isShowing}
            className="modal-transition"
            classNames="modal-transition"
            unmountOnExit
            timeout={props.disabledAnimation ? 0 : duration}>
          <ModalWithTransitionStyles
              title={props.title}
              hide={props.hide}
              position={props.position}
              widthPercentage={props.widthPercentage}
              isShowing={props.isShowing}
              darkMode={props.darkMode}
              darkModeBgColor={props.darkModeBgColor}
              closeOnDocumentClick={props.closeOnDocumentClick}>
            {props.children}
          </ModalWithTransitionStyles>
        </CSSTransition>
        <CSSTransition
            in={props.isShowing}
            className="background-transition"
            classNames="background-transition"
            unmountOnExit
            timeout={props.disabledAnimation ? 0 : duration}>
          <BehindBackground/>
        </CSSTransition>
      </>
    )
}

SlidingSidebar.defaultProps = DefaultProps

export default SlidingSidebar