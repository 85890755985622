import * as React from 'react'
import Sidebar from './SlidingSidebar';

const { useState } = React

const useSidebar = () => {
    const [isShowing, setIsShowing] = useState<boolean>(false);

    function toggle(): void {
        setIsShowing(!isShowing);
    }
    
    function open(): void {
        setIsShowing(true)
    }
    
    function close(): void {
        setIsShowing(false)
    }

    return {
        isShowing,
        toggle,
        open,
        close,
        Sidebar
    };
}

export default useSidebar;