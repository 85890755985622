import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const ModalBackground = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled('div')<any>`
    width: ${props => props.width+"vw"};
    background: ${props => props.darkMode ? props.darkModeBgColor ? props.darkModeBgColor : "#2a3c4e" : "#fff"};
    height: 100%;
    z-index: 999999;
    overflow-y: auto;
    overflow-x: auto;
    position: absolute;
    top: 0;
    left: ${props => props.position === "left" ? 0 : "initial"};
    right: ${props => props.position === "right" ? 0 : "initial"};
    box-sizing: border-box;
    box-shadow:
    0 0px 3.6px rgba(0, 0, 0, 0.021),
    0 0px 10px rgba(0, 0, 0, 0.03),
    0 0px 24.1px rgba(0, 0, 0, 0.039),
    0 0px 80px rgba(0, 0, 0, 0.06);
  @media only screen and (max-width: 540px){
    width: 100vw !important;
  }

`;

const ModalHeader = styled("div")<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  line-height: 50px;
  background: #F3F3F3;
  padding-left: 10px;
  h3{
    font-size: 16px;
    color: ${props => props.darkMode ? "#bccde0" : "#4F4F4F"};
  }
  .modal-close-button {
    font-size: 2.2rem;
    font-weight: 700;
    color: ${props => props.darkMode ? "#bccde0" : "#435F71"};
    margin-right: .5rem;
    cursor: pointer;
    border: none;
    background: transparent;
  }
`;

type Props = {
  hide(): void,
  title: string
  widthPercentage?: number
  closeOnDocumentClick?: boolean
  children: React.ReactNode
  className?:string
  isShowing:boolean
  position?:"right" | "left" 
  darkMode?: boolean
  darkModeBgColor?: string
}

const Sidebar: React.FC<Props> = (props) => {

  const node = React.useRef()

  const handleClick = e => {
    //@ts-ignore
    if (node.current && node.current.contains(e.target)) {
        return;
    }
    !!props.closeOnDocumentClick && props.hide()
  };
  
    //Active listener to close when press escape & submit when press enter
    const handleKeyPress = (e: any) => {
      if(e.key === "Escape"){
        props.hide()
        document.removeEventListener("keydown", handleKeyPress)
        return
      }
    }
  
    React.useEffect(() => {
      document.addEventListener("keydown", handleKeyPress)
      return () => document.removeEventListener("keydown", handleKeyPress)
    })

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, []);

  const modalMarkup = (
    <ModalBackground className={props.className}>
      <ModalWrapper width={props.widthPercentage} ref={node} position={props.position} darkMode={props.darkMode} darkModeBgColor={props.darkModeBgColor}>
        <ModalHeader darkMode={props.darkMode}>
          <h3>{props.title}</h3>
            <button
              type="button"
              className="modal-close-button"
              onClick={props.hide}>
              <span>&times;</span>
          </button>
        </ModalHeader>
        {props.children}
      </ModalWrapper>
    </ModalBackground>
  );

  return ReactDOM.createPortal(modalMarkup, document.body);
};

export default Sidebar;